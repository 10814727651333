import { useContext, useState } from 'react';
import { Avatar, Divider, List } from 'antd';
import cmsFactory from '../components/cmsFactory';
import createComponent from '../components/contentDisplay/dynamic';
import AppCard from '../components/panels/AppCard';
import ReportSummaries from '../components/landing/ReportSummaries';
import htmlStringToReactNodes from '../helpers/htmlStringToReactNodes';
import { ReportsAccessProvider } from '../lib/ReportsAccessContext';
import UiContext from '../lib/UiContext';
import styles from './index.module.scss';
const HomeLayout = ({
  mainBanner,
  mainBottom,
  mainCenter,
  asideBottom,
  asideTop
}) => <article className={styles["home-grid-container"]} data-sentry-component="HomeLayout" data-sentry-source-file="index.jsx">
    <section className={styles["main-banner-wrapper"]} children={mainBanner} />
    <section className={styles["main-center-wrapper"]} children={mainCenter} />
    <section className={styles["main-bottom-wrapper"]} children={mainBottom} />
    <section className={styles["aside-top-wrapper"]} children={asideTop} />
    <section className={styles["aside-bottom-wrapper"]} children={asideBottom} />
  </article>;
const Carousel = ({
  items
}) => {
  const [current, goTo] = useState(0);
  return <div className="homepage-carousel" data-sentry-component="Carousel" data-sentry-source-file="index.jsx">
      <div className="homepage-carousel-slider" style={{
      transform: `translateX(-${current * 100}%)`
    }} children={items} />
      {items.length > 1 && <div className="homepage-carousel-dots" children={items.map((_, i) => <button className={i === current ? 'current' : null} key={i} onClick={() => goTo(i)} />)} />}
    </div>;
};
const LandingPage = ({
  data: {
    carousel,
    navigation,
    blogLinks
  }
}) => {
  const {
    apps,
    curricula
  } = useContext(UiContext);
  const appsAvailable = apps.filter(app => app.accessible);
  const curriculaAvailable = curricula.filter(app => app.accessible);
  return <HomeLayout mainBanner={<Carousel items={carousel.map(props => ({
    ...props,
    ...Object.fromEntries(Object.keys(props).filter(k => k.endsWith('HTML')).map(k => [k.replace(/HTML$/, ''), htmlStringToReactNodes(props[k])]))
  })).map(createComponent)} />} mainCenter={<>
          <h2 children="Teacher Toolkits" />
          {navigation.map(createComponent).flatMap((c, i) => [c, <Divider key={`divider-${i}`} />]).slice(0, -1)}
        </>} mainBottom={<>
          {curriculaAvailable.length > 0 && <>
              <h2 children="Curricula" />
              <div className={styles["app-list"]} children={curriculaAvailable.map(app => <AppCard app={app} key={app.id} />)} />
            </>}
          {appsAvailable.length > 0 && <>
              <h2 children="Programs" />
              <div className={styles["app-list"]} children={appsAvailable.map(app => <AppCard app={app} key={app.id} />)} />
            </>}
        </>} asideTop={<ReportsAccessProvider children={<ReportSummaries />} />} asideBottom={<>
          <h2 className="title" children="Latest from our Blogs" />
          <List itemLayout="horizontal" dataSource={blogLinks} renderItem={({
      iconURL,
      externalURL,
      title,
      name
    }) => <List.Item>
                <List.Item.Meta avatar={<Avatar src={iconURL} />} title={<a href={externalURL} target="_blank" children={title || name} />} />
              </List.Item>} />
        </>} data-sentry-element="HomeLayout" data-sentry-component="LandingPage" data-sentry-source-file="index.jsx" />;
};
export const getStaticProps = context => ({
  props: {
    dataURL: '/content/singletons/homePage.json'
  }
});
export default cmsFactory(LandingPage);